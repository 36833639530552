import React, { useEffect } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LinkIcon from '@mui/icons-material/Link';
import { rtColors } from '@ritualco/ritual-frontend-components';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import StandardModal from '@artemis/components/StandardModal';
import { setHostInfoModal } from '@artemis/store/groupOrder/slice';
import { getIsHostInfoModalOpen } from '@artemis/store/groupOrder/selectors';
import {
  getMerchantId,
  getMerchantName,
} from '@artemis/store/merchant/selectors';
import { logGroupOrderIntroModalImpression } from '@artemis/containers/GroupOrder/analytics';
import { getUserDeliveryAddressId } from '@artemis/store/cart/selectors';
import { FEATURE_INFO_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import { useCreateGroupOrderFulfillment } from '@artemis/utils/useCreateGroupOrderFulfillment';
import { Container, Image, StyledImage, SubTitle, Title } from './components';

const Row = styled.div`
  display: flex;
  margin: 12px 0px;
`;

const Cell = styled.div`
  flex-direction: column;
  margin-left: 24px;
`;

const CellTitle = styled.h6`
  ${props => props.theme.typography.h6};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const CellDesc = styled.p`
  ${props => props.theme.typography.body};
`;

const HostInfoModal = () => {
  const dispatch = useDispatch();
  const merchantId = useSelector(getMerchantId);
  const merchantName = useSelector(getMerchantName);
  const deliveryAddressId = useSelector(getUserDeliveryAddressId);
  const isOpen = useSelector(getIsHostInfoModalOpen);

  const [createGroupOrderFulfillment] = useCreateGroupOrderFulfillment({
    merchantId,
    merchantName,
    deliveryAddressId,
  });
  const learn = useQueryCheck(FEATURE_INFO_PARAM);

  useEffect(() => {
    if (isOpen) {
      logGroupOrderIntroModalImpression({
        merchantId,
        isMerchantPageSource: learn,
      });
    }
  }, [isOpen]);

  const onContinue = () => {
    dispatch(setHostInfoModal({ isOpen: false }));
    createGroupOrderFulfillment();
  };

  const onClose = () => {
    dispatch(setHostInfoModal({ isOpen: false }));
  };

  return (
    <StandardModal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonText={
        <FormattedMessage entry="groupOrder.infoModal.continue" />
      }
      onClickPrimaryButton={onContinue}
    >
      <Image>
        <StyledImage id="groupOrder.infoModal.header.img" />
      </Image>
      <Container>
        <Title>
          <FormattedMessage entry="groupOrder.infoModal.title" />
        </Title>
        <SubTitle>
          <FormattedMessage entry="groupOrder.infoModal.subTitle" />
        </SubTitle>
        <Row>
          <ScheduleIcon
            sx={{
              backgroundColor: rtColors.teal100,
              borderRadius: '100%',
              color: rtColors.teal500,
              fontSize: 22,
              padding: '5px',
            }}
          />
          <Cell>
            <CellTitle>
              <FormattedMessage entry="groupOrder.infoModal.scheduleAhead" />
            </CellTitle>
            <CellDesc>
              <FormattedMessage entry="groupOrder.infoModal.scheduleAheadDesc" />
            </CellDesc>
          </Cell>
        </Row>
        <Row>
          <LinkIcon
            sx={{
              backgroundColor: rtColors.teal100,
              borderRadius: '100%',
              color: rtColors.teal500,
              fontSize: 22,
              padding: '5px',
            }}
          />
          <Cell>
            <CellTitle>
              <FormattedMessage entry="groupOrder.infoModal.spendingLimit" />
            </CellTitle>
            <CellDesc>
              <FormattedMessage entry="groupOrder.infoModal.spendingLimitDesc" />
            </CellDesc>
          </Cell>
        </Row>
      </Container>
    </StandardModal>
  );
};

export default HostInfoModal;
