import styled from 'styled-components';
import ResponsiveImage from '@artemis/components/ResponsiveImage';

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.rtColors.yellow100};
  margin: -70px -32px 16px;
`;

export const StyledImage = styled(ResponsiveImage)`
  margin: 33px 0px;
`;

export const Title = styled.h1`
  ${props => props.theme.typography.h3};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  text-align: center;
  margin-bottom: 28px;
`;

export const SubTitle = styled.h3`
  ${props => props.theme.typography.h6};
  margin-bottom: 12px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  margin: 0px 16px;
  ${({ theme }) => theme.noScrollbars};
  ${({ theme }) => theme.isTablet`
    border-radius: 4pt;
  `}
`;
